body {
  margin: 0;
  
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.loader {
  position: fixed;
  border: 24px solid #FFF;
  border-radius: 50%;
  top: 50%;
  z-index: 9999;
  left: 50%;
  transform: rotate(45deg);
  animation: pieFill 3s linear infinite;
}

.loader-box {
  width: 100%;
  height: 100%;
  background-color: black;
  opacity: 0.4;
}

@keyframes pieFill {
  0% , 19%{ border-color: #FFF #FFF #FFF #FFF }
  20% , 39%{ border-color: #60af2c #FFF #FFF #FFF }
  40% , 59%{ border-color: #60af2c #60af2c #FFF #FFF }
  60% , 79%{ border-color: #60af2c #60af2c #60af2c #FFF }
  80% , 100% { border-color: #60af2c #60af2c #60af2c #60af2c }
}
